@font-face {
    font-family: "Montserrat";
    src: url("../fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat";
    src: url("../fonts/Montserrat-Bold.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "Montserrat";
    src: url("../fonts/Montserrat-Light.ttf") format("truetype");
    font-weight: 300;
}

@font-face {
    font-family: "Montserrat";
    src: url("../fonts/Montserrat-ExtraLight.ttf") format("truetype");
    font-weight: 200;
}

@font-face {
    font-family: "CormorantGaramond";
    src: url("../fonts/CormorantGaramond-Light.ttf") format("truetype");
    font-weight: 300;
}

@font-face {
    font-family: "CormorantGaramond";
    src: url("../fonts/CormorantGaramond-Bold.ttf") format("truetype");
    font-weight: bold;
}
