.fast-nav {
    display: flex;

    .logo-m {
        position: absolute;
        top: 55px;
        height: 40px;
        left: calc(50% - 20px);

        @media (min-width: 569px) {
            top: 0;
            height: 48px;
            left: calc(50% - 24px);
    } 

        @media (min-width: 773px) {
            height: 70px;
            left: calc(50% - 35px);
        }
    }

    .right {
        margin-left: auto;
        position: relative;

        a {
            margin-left: 5px;

            @media (min-width: 772px) {
                margin-left: 12px;
            }
        }

        .lang-switch {
            position: absolute; 
            font-size: 14px; 
            color: #fff;
            top: -25px;
            left: 12px;

            @media (min-width: 772px) {
                left: 20px;
            }
            @media (min-width: 792px) {
                left: 72px;
            }

            @media (min-width: 1000px) {
                top: -35px;
            } 

            @media (min-width: 1201px) {
                left: 142px;
            } 

            a {
                display: inline-block;
                color: #fff;
                margin-left: 5px;
                margin-right: 5px;
                padding: 5px;
                text-decoration: none;

                &.active {
                    font-weight: bold;
                }
            }
        }
    }

    .btn {
        display: inline-block;
        height: 48px;
        line-height: 48px;
        border-radius: 24px;
        letter-spacing: 0.15em;
        position: relative;
        color: white;
        cursor: pointer;
        text-decoration: none;

        @media (min-width: 772px) {
            height: 58px;
            line-height: 58px;
            border-radius: 29px;
        }
    }

    .open-menu {
        @extend .btn;
        padding-left: 80px;
        padding-right: 35px;
        background-color: black;

        img {
            width: 25px;
            position: absolute;
            left: 30px;
            transition: transform 0.2s ease-in-out;

            @media (max-width: 900px) {
                left: 17px;
            }

            @media (max-width: 772px) {
                left: 12px;
            }

            &.m1 {
                top: 14px;
            }

            &.m2 {
                top: 20px;
            }
            &.m3 {
                top: 26px;
            }

            @media (max-width: 772px) {
                left: 12px;

                &.m1 {
                    top: 11px;
                }

                &.m2 {
                    top: 17px;
                }
                &.m3 {
                    top: 23px;
                }
            }
        }

        &:hover {
            img {
                transform: rotate(27.3deg);
            }
        }

        &.hide {
            z-index: -10;
            opacity: 0;
            visibility: hidden;
        }

        @media (max-width: 900px) {
            width: 58px;
            overflow: hidden;
            padding: 0;
            color: transparent;
        }

        @media (max-width: 772px) {
            width: 48px;
        }
    }

    .offices {
        @extend .btn;
        padding-left: 15px;
        padding-right: 12px;
        background-color: #968c6b;
        font-size: 12px;

        img {
            width: 32px;
            position: absolute;
            left: 30px;
            top: 14px;

            @media (max-width: 791px) {
                display: none;
            }
        }

        @media (min-width: 792px) {
            padding-left: 80px;
            padding-right: 35px;
            font-size: 14px;
        }
    }

    .favorite {
        @extend .btn;
        width: 58px;
        border: 1px solid white;

        img {
            width: 24px;
            position: absolute;
            top: 14px;
            left: 16px;
        }

        @media (max-width: 1200px) {
            display: none;
        }
    }

    .mail,
    .phone,
    .home {
        @extend .btn;
        width: 48px;

        @media (min-width: 772px) {
            width: 58px;
        }

        img {
            position: absolute;
        }
    }

    .mail {
        background-color: #afa27c;

        img {
            width: 25px;
            top: 12px;
            left: 11px;

            @media (min-width: 772px) {
                width: 28px;
                top: 14px;
                left: 14px;
            }
        }
    }
    .phone {
        background-color: #cab98e;

        img {
            width: 20px;
            top: 13px;
            left: 16px;

            @media (min-width: 772px) {
                width: 22px;
                top: 16px;
                left: 20px;
            }
        }
    }

    .logo {
        display: flex;

        @media (max-width: 568px) {
            position: absolute;
            top: 67px;
            left: 20px;
            width: 130px;
        }

        img {
            width: 150px;
            margin-left: 10px;
            vertical-align: middle;

            @media (min-width: 772px) {
                margin-left: 20px;
                width: 185px;
            }

            @media (min-width: 1000px) {
                margin-left: 50px;
            } 
        }
    }

    .home {
        @extend .btn;
        float: right;
        background-color: #000;
        display: none;

        img {
            display: block;
            margin: 0 auto;
            width: 20px;
            height: 20px;
            position: absolute;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            left: 0;
            right: 0;
        }
    }
}

header {
    opacity: 0;
    transition: opacity 1000ms ease-in-out;
    position: fixed;
    z-index: 20;
    top: 30px;
    padding-left: 30px;
    padding-right: 30px;

    @media (min-width: 1000px) {
        top: 80px;
        padding-left: 70px;
        padding-right: 70px;
    }

    width: 100%;

    .close {
        width: 48px;
        height: 48px;
        border-radius: 24px;
        background-color: white;
        position: absolute;
        left: 30px;
        top: 0;

        @media (min-width: 772px) {
            width: 58px;
            height: 58px;
            border-radius: 29px;
        }

        @media (min-width: 1000px) {
            left: 70px;
        }

        &:hover,
        &:focus {
            cursor: pointer;
        }

        img {
            display: block;
            margin: 0 auto;
            width: 20px;
            height: 20px;
            position: absolute;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            left: 0;
            right: 0;
        }
    }

    .open-menu,
    .close {
        z-index: 1;
        opacity: 1;
        visibility: visible;
        -webkit-transition: opacity 600ms, visibility 600ms, z-index 600ms;
        transition: opacity 600ms, visibility 600ms, z-index 600ms;

        &.hide {
            z-index: -10;
            opacity: 0;
            visibility: hidden;
        }
    }
}

main.routeGallery header {
    #openModalBtn,
    #closeModalBtn {
        opacity: 0;
        pointer-events: none;
    }

    .favorite,
    .offices,
    .mail,
    .phone {
        display: none;
    }

    .home {
        display: block;
    }
}

aside {
    position: fixed;
    visibility: hidden;
    z-index: 0;
    top: 35%;
    right: -380px;
    opacity: 0;
    transition: opacity 1000ms ease-in-out, right 900ms ease-in-out 200ms;

    @media (min-width: 768px) {
        visibility: visible;
        z-index: 20;
    }

    @media (min-width: 800px) {
        right: -330px;
    }

    ul {
        width: 420px;
        li {
            position: relative;
            margin-bottom: 20px;
            white-space: nowrap;
            transition: width 700ms ease-in-out;
            width: 0;
            text-align: right;

            a {
                color: white;
                font-size: 14px;
                text-decoration: none;
                letter-spacing: 3.5px;

                .line {
                    display: inline-block;
                    margin-left: 15px;
                    width: 100px;
                    border-bottom: 1px solid transparent;
                    position: relative;
                    top: -8px;
                }

                &:hover .line {
                    border-bottom-color: white;
                }
            }
        }
    }

    &:hover {
        right: 0;

        ul li {
            width: 100%;
        }
    }
}

.routeHome aside.znacilnostiInPrednostiEle:hover ul li:nth-child(1) a {
    position: relative;
    left: -100px;
}

.routeGallery aside {
    top: unset;
    bottom: 10%;
}

.routeHome aside.znacilnostiInPrednostiEle ul li:nth-child(1) a .line,
.routeHome aside.napredekEle ul li:nth-child(2) a .line,
.routeOffices aside:not(.kontaktiEle) ul li:nth-child(3) a .line,
.routeOffices aside.kontaktiEle ul li:nth-child(5) a .line,
.routeGallery aside ul li:nth-child(4) a .line {
    width: 200px;
    border-bottom-color: white;
}

body.modal-open {
    aside {
        visibility: hidden;
    }

    header.fast-nav .right {
        margin-right: 17px;
    }
}
