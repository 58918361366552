#trMonTxt {
    position: fixed;
    height: 33vh;
    top: 25vh;
    left: -3.5vw;
}

#trTanaTxt {
    position: fixed;
    height: 33vh;
    top: 51.3vh;
    left: 13.5vw;
}

.go-to {
    left: calc(50% - 35px);
    max-width: 50%;
    position: absolute;
    bottom: 117px;

    &.fx {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        position: -webkit-sticky;
        position: sticky;
        bottom: unset;
        top: 80vh;
        height: 15vh;

        @media (min-width: 500px) {
            top: 85vh;
        } 
    }

    img {
        animation: MoveUpDown 1s ease-in-out infinite;
        position: absolute;
        left: 0;
        top: 0;
        width: 70px;
    }
}

@keyframes MoveUpDown {
    0%,
    100% {
        top: 0;
    }
    50% {
        top: 20px;
    }
}
