section {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    width: 100%;
    position: relative;

    .s-title {
        font-size: 44px;
        line-height: 50px;
        font-family: "CormorantGaramond";
        color: #fff;
        text-align: center;
        margin-left: auto;
        margin-right: auto;

        .num {
            font-size: 121px;
        }

        @media (min-width: 1000px) {
            font-size: 74px;
            line-height: 88px;
        }
    }

    .anim-title {
        font-size: 18px;
        line-height: 22px;
        position: absolute;
        left: 15%;
        top: 100px;
        color: #fff;
        font-weight: 200;
        letter-spacing: 12px;
        z-index: 1;

        @media (min-width: 1000px) {
            left: 25%;
            font-size: 31px;
            line-height: 45px;
            top: 200px;
        }
    }

    &#razsiriteSvojPotencialEle {
        height: 200vh;
        background-image: url(../images/section-bg/razsiriteSvojPotencial.jpg);

        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;

        .s-title {
            -webkit-box-flex: 3;
            -ms-flex: 3;
            flex: 3;
            position: -webkit-sticky;
            position: sticky;
            top: 45vh;
            height: 30vh;
            right: 0;
        }
    }

    &#znacilnostiInPrednostiEle {
        background-color: #cab98e;
        padding-top: 35vh;
        position: relative;
        overflow: hidden;

        img.entrance {
            z-index: 1;
            position: absolute;
            top: 10vh;
            right: 5vw;
            width: 55vw;
        }

        .location-map-wrapper {
            margin: 0 15vw 10vh;
            position: relative;

            img {
                &.location-map-roads {
                    width: 100%;
                }

                &.location-map-fields,
                &.location-map-texts {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                }
            }
        }
    }

    &#napredekEle {
        min-height: 150vh;
        background-image: url(../images/section-bg/napredek.jpg);

        .top-content {
            min-height: 150vh;
            padding-top: 50vh;

            .framed-text {
                background-color: rgba(255, 255, 255, 0.85);
                padding: 30px;
                text-align: center;
                color: #000;
                margin-left: auto;
                margin-right: auto;
                width: 80%;
                line-height: 24px;
                margin-bottom: 50px;

                @media (min-width: 1000px) {
                    width: 50%;
                    padding: 100px;
                }
            }

            .s-title {
                margin-bottom: 20vh;
            }
        }
    }

    &#pisarneEle {
        background-image: url(../images/section-bg/pisarne.jpg);

        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;

        .quote-mark {
            position: absolute;
            bottom: -213px;
            left: calc(50% - 315px);
            width: 371px;
        }

        .s-title {
            position: absolute;
            top: 45vh;
            height: 30vh;
            left: 0;
            right: 0;
        }
    }

    &#kontaktiEle {
        min-height: 70vh;
        background-image: url(../images/section-bg/kontakt.jpg);

        .top-content {
            height: 40vh;
        }
    }

    &#galerijaEle {
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.8);

        .info {
            position: absolute;
            color: #fff;
            z-index: 1;
            height: 58px;
            line-height: 58px;
            top: 30px;
            left: 30px;
            font-size: 14px;
            letter-spacing: 3.5px;

            @media (min-width: 569px) and (max-width: 771px) {
                top: 60px;
                left: 137px;
            }

            @media (min-width: 772px) and (max-width: 901px) {
                top: 65px;
                left: 170px;
            }

            @media (min-width: 1000px) {
                top: 80px;
                left: 70px;
            }
        }
    }
}
