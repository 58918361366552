$floor-height: 6.6%;

.cell-box {
    overflow: hidden;

    &:not(.no-margin) {
        margin-left: 40px;
    }

    h3 {
        font-size: 31px;
        color: #c9b88d;
        font-weight: 200;
    }

    p {
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 25px;
        max-width: 550px;
    }

    &.border {
        border-top: 1px solid #000;
        padding-top: 25px;
    }

    &.padding {
        padding: 60px 20px 80px 0;
    }

    .floor-label {
        display: block;
        border-top: 1px solid transparent;
        position: relative;
        font-size: 17px;
        color: #b0a37d;
        cursor: pointer;

        @media (max-width: 600px) {
            line-height: 30px !important;
        }

        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            background: linear-gradient(to right, #000, rgba(0, 0, 0, 0));
            top: -1px;
            left: 0;
        }

        &.last {
            border-bottom: 1px solid transparent;
            &:after {
                content: "";
                position: absolute;
                width: 100%;
                height: 1px;
                background: linear-gradient(to right, #000, rgba(0, 0, 0, 0));
                bottom: -1px;
                left: 0;
            }
        }

        sup {
            font-size: 10px;
            position: relative;
            top: -5px;
            left: 10px;
        }

        span {
            font-family: "CormorantGaramond";
            font-weight: 300;
            font-size: 18px;
            color: #958b6a;
            display: inline-block;
            padding: 0 60px 0 40px;
        }

        &.selected {
            color: #000;
            font-weight: bold;

            span {
                font-weight: bold;
            }
        }

        &:first-child {
            @media (min-width: 600px) {
                margin-top: 17.7%;
            }
        }
    }

    .building-plan-wrapper {
        position: relative;

        .selection-overlay {
            position: absolute;
            left: 7.4%;
            right: 5.4%;
            height: $floor-height;
            background-color: #b2995a;
            opacity: 0;
            transition: all 50ms ease-in-out;
        }

        img {
            width: 100%;
        }
    }

    .floor-plan {
        max-width: 100%;
        width: 240px;
        height: 320px;
        display: block;
        margin: 10px auto;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        transition: all 200ms ease-in-out;

        @media (min-width: 1000px) {
            width: 200px;
            height: 270px;
            margin: 80px auto;
        }
        @media (min-width: 1200px) {
            width: 240px;
            height: 320px;
        }
        @media (min-width: 1400px) {
            width: 300px;
            height: 400px;
            margin: 100px auto;
        }

        &.floor-1 {
            background-image: url(../images/floors/1.png);
        }
        &.floor-2 {
            background-image: url(../images/floors/2.png);
        }
        &.floor-3 {
            background-image: url(../images/floors/3.png);
        }
        &.floor-4 {
            background-image: url(../images/floors/4.png);
        }
        &.floor-5 {
            background-image: url(../images/floors/5.png);
        }
        &.floor-6 {
            background-image: url(../images/floors/6.png);
        }
        &.floor-7 {
            background-image: url(../images/floors/7.png);
        }
        &.floor-8 {
            background-image: url(../images/floors/8.png);
        }
        &.floor-9 {
            background-image: url(../images/floors/9.png);
        }

        &.hidden {
            opacity: 0;
            transform: scale(0);
            transform: translate(300px);
        }
    }
}

.download-floor {
    text-align: center;
    margin: 30px 20px;
    height: 116px;

    @media (min-width: 700px) {
        margin: 80px 50px 100px;
    }

    a {
        height: 58px;
        line-height: 58px;
        letter-spacing: 0.15em;
        border-radius: 29px;
        position: relative;
        color: white;
        cursor: pointer;
        text-decoration: none;
        display: none;

        padding-left: 80px;
        padding-right: 35px;
        background-color: #958b6a;

        img {
            width: 32px;
            position: absolute;
            left: 30px;
            top: 14px;
        }

        &.visible {
            display: inline-block;
        }
    }
}
