.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(150, 140, 107);
  background-color: rgba(150, 140, 107, 0.85);
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 600ms;
  transition: all 600ms;

  &.open {
    z-index: 10;
    opacity: 1;
    visibility: visible;
  }

  .modal-content {
    margin-top: 30vh;
    margin-left: 40px;
    max-width: 680px;

    @media (min-width: 772px) {
      margin-left: 175px;
    }
    @media (min-width: 901px) {
      margin-left: 275px;
    }

    @media (min-width: 1000px) {
      margin-left: 315px;
    }
    @media (min-width: 1200px) {
      margin-left: 455px;
    }

    nav ul li {
      margin-bottom: 2vh;

      @media (min-width: 772px) {
        margin-bottom: 4vh;
      }

      a {
        color: #cab98e;
        font-size: 25px;
        text-decoration: none;
        font-weight: bold;
        letter-spacing: 1.5px;

        @media (min-width: 772px) {
          font-size: 30px;
          letter-spacing: 3.5px;
        }
        @media (min-width: 1000px) {
          font-size: 34px;
        }

        &:hover,
        &.active {
          color: white;
        }
      }
    }

    .dgnb {
      position: absolute;
      right: 17px;
    }
  }
}

body.modal-open .modal .modal-content {
  max-width: 697px;
}
