// https://codepen.io/flpwgr/pen/qbMJaY

.container {
    width: 100%;
}

.row {
    display: flex;
    flex-wrap: wrap;
}

.column {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 1;
}

.col-1 {
    max-width: 0;

    @media (min-width: 700px) {
        max-width: 22%;
    }
}
.col-2 {
    max-width: 0;

    @media (min-width: 1000px) {
        max-width: 33%;
    }
}

.col-3 {
    max-width: 100%;

    @media (min-width: 700px) {
        max-width: 78%;
    }

    @media (min-width: 1000px) {
        max-width: 45%;
    }
}

.col-1-2 {
    max-width: 100%;

    @media (min-width: 1000px) {
        max-width: 55%;
    }
}

.col-1-2-a {
    max-width: 100%;

    @media (min-width: 600px) {
        max-width: 40%;
    }
}

.col-1-2-b {
    max-width: 100%;

    @media (min-width: 600px) {
        max-width: 60%;
    }
}

.col-1-2-3 {
    max-width: 100%;

    @media (min-width: 1000px) {
        max-width: 45%;
    }
}

.gt1000 {
    display: none;

    @media (min-width: 1000px) {
        display: block;
    }
}

.lt1000 {
    display: block;

    @media (min-width: 1000px) {
        display: none;
    }
}
