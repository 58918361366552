* {
    box-sizing: border-box;
}

body {
    height: 100vh;
    margin: 0;
    font-family: "Montserrat" !important;
    font-size: 14px;

    &.modal-open {
        overflow: hidden;
        padding-right: 17px;
    }
}

b {
    font-weight: bold;
}
