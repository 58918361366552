.glide {
    height: 100vh;
    .glide__slide {
        width: 100vw !important;
        height: 100vh !important;
    }

    .glide__arrows {
        position: absolute;
        top: 45%;
        width: 100%;

        .glide__arrow {
            position: absolute;
            cursor: pointer;
            width: 80px;
            height: 80px;
            background: transparent;
            border: none;

            img {
                width: 100%;
            }

            &.glide__arrow--left {
                left: 10px;
                transform: rotateZ(90deg);

                @media (min-width: 800px) {
                    left: 30px;
                }
            }

            &.glide__arrow--right {
                right: 10px;
                transform: rotateZ(-90deg);
                
                @media (min-width: 800px) {
                    right: 30px;
                }
            }
        }
    }
}

#gallerySlider {
    .glide__slide {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        &.s0 {
            background-image: url(../images/gallery/slide8.jpg);
        }
        &.s1 {
            background-image: url(../images/gallery/slide1.jpg);
        }
        &.s2 {
            background-image: url(../images/gallery/slide2.jpg);
        }
        &.s3 {
            background-image: url(../images/gallery/slide3.jpg);
        }
        &.s4 {
            background-image: url(../images/gallery/slide4.jpg);
        }
        &.s5 {
            background-image: url(../images/gallery/slide5.jpg);
        }
        &.s6 {
            background-image: url(../images/gallery/slide6.jpg);
        }
        &.s7 {
            background-image: url(../images/gallery/slide7.jpg);
        }
        &.s8 {
            background-image: url(../images/gallery/slide0.jpg);
        }
    }
}
