// zamenjaj s flex
// https://codepen.io/flpwgr/pen/qbMJaY
.clearfix {
    &::after {
        display: block;
        clear: both;
        content: "";
    }

    @media (min-width: 800px) {
        &.m800 {
            display: none;
        }
    }
}

.clearfix.spacer {
    @media (max-width: 800px) {
        margin-bottom: 200px;
    }
}

.dgnb {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    position: -webkit-sticky;
    position: sticky;
    height: 50vh;
    color: white;
    margin: 0;
    top: 50vh;
    right: 0;
    opacity: 0;
    transition: opacity 1000ms ease-in-out;

    img {
        height: 48px;
        position: absolute;
        bottom: 20px;
        right: 20px;
    }

    .dgnb-text {
        height: 32px;
        width: 175px;
        position: absolute;
        bottom: 13px;
        right: 85px;
        font-size: 10px;
        line-height: 14px;
        font-weight: bold;
        text-align: right;
        vertical-align: bottom;
    }

    @media (min-width: 768px) {
        img {
            height: 58px;
            bottom: 58px;
            right: 72px;
        }
        .dgnb-text {
            bottom: 58px;
            right: 150px;
            font-size: 12px;
            line-height: 16px;
        }
    }
}
