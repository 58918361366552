.advantages {
    position: relative;
    padding: 0 5px;

    @media (min-width: 800px) {
        padding: 0 70px;
    }

    .line {
        height: 0.5px;
        background-color: #fff;
        left: 0;
        right: 0;
        top: 50%;
        position: absolute;
    }

    .lines {
        position: absolute;
        top: calc(50% - 50px);

        &.small-double,
        &.small-double-wide {
            height: 100px;
            border: 0.5px solid #fff;
            border-top-width: 0;
            border-bottom-width: 0;
        }

        &.small-double {
            width: 10px;
        }
        &.small-double-wide {
            width: 30px;
        }

        &.small-single,
        &.large-single {
            border-left: 0.5px solid #fff;
        }

        &.small-single {
            height: 50px;
            top: 25px;
        }
        &.large-single {
            height: 200px;
            top: -50px;
        }
    }

    .item {
        display: block;
        float: left;
        width: 50%;
        text-align: center;
        color: white;
        letter-spacing: 1.5px;
        margin: 15px 0;
        font-size: 12px;
        padding: 0 7px;

        @media (min-width: 800px) {
        letter-spacing: 3px;
        margin: 50px 0;
            width: 25%;

            font-size: 13px;
            font-weight: bold;
        }

        .ico {
            height: 90px;
            margin-bottom: 30px;

            @media (min-width: 800px) {
                width: 70px;

                &.w55 {
                    width: 55px;
                }
            }

            img {
                display: inline-block;
                width: 49px;
                position: absolute;
                bottom: 50px;
                left: calc(50% - 25px);

                &.w55 {
                    width: 38.5px;
                }

                @media (min-width: 800px) {
                    width: 70px;
                    left: calc(50% - 35px);

                    &.w55 {
                        width: 55px;
                    }
                }
            }
        }

        &.under .ico img {
            top: 50px;
            bottom: unset;
        }
    }
}
