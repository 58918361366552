.footer-contact {
    background-color: rgba(0, 0, 0, 0.8);
    padding: 30px;

    @media (min-width: 1000px) {
        padding: 70px;
    }

    .logo img {
        @media (min-width: 1100px) {
            margin-left: 383px;
        }
    }

    .address {
        margin: 70px 0 10px 10px;
        color: #fff;
        line-height: 22px;

        @media (min-width: 569px) {
            margin-top: 40px;
        }


        @media (min-width: 772px) {
            margin-left: 83px;
        }

        @media (min-width: 1100px) {
            margin-left: 383px;
        }

        a {
            color: #fff;
            text-decoration: none;
        }
    }

    .fast-nav {
        position: relative;

        .logo {
            @media (max-width: 568px) {
                left: 0;
                top: 70px;
            }
        }
    }

    .stoja {
        width: 100px;
        display: block;
        margin-top: 25px;
        margin-bottom: 5px;
    }

    .space {
        height: 10px;
    }
}
